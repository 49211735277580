<template>
  <div>
    <Header :languagesProp="'English'" />
    <div class="contentBox">
      <!-- banner Box -->
      <img
        style="width: 100%"
        src="@/assets/image/newAbout/aboutBanner.png"
        alt=""
      />
      <!-- platFormBox -->
      <div class="platFormBox">
        <!-- <img
          style="margin-bottom: 16px; width: 112px"
          src="@/assets/image/newHome/titleTop.png"
          alt=""
        /> -->
        <h2>Platform Advantages</h2>
        <p class="remark">Advantage over others</p>
        <ul class="contentList">
          <li>
            <img src="@/assets/image/newAbout/p1.png" alt="" />
            <h5>Service advantages</h5>
            <p>
              Professional, scientific and all-round digital operation
            </p>
          </li>
          <li>
            <img src="@/assets/image/newAbout/p2.png" alt="" />
            <h5>Team advantages</h5>
            <p>
              Mature and professional financial, operations and technical teams
            </p>
          </li>
          <li>
            <img src="@/assets/image/newAbout/p3.png" alt="" />
            <h5>Safe and secure</h5>
            <p>
              Encrypt your information with encryption algorithms
            </p>
          </li>
          <li>
            <img src="@/assets/image/newAbout/p4.png" alt="" />
            <h5 style="width: 350px">
              Digital risk control system
            </h5>
            <p style="width: 320px">
              Strong technological innovation capability, digital riskcontrol
              model
            </p>
          </li>
        </ul>
      </div>

      <!-- operation Box -->
      <div class="operationBox">
        <!-- <img
          style="margin-bottom: 16px; width: 112px; margin-left: 16px"
          src="@/assets/image/newHome/titleTop.png"
          alt=""
        /> -->
        <h2>Operation Team</h2>
        <p class="remark">Professional service</p>
        <ul>
          <li>
            <img src="@/assets/image/newAbout/t1.png" alt="" />
            <div>
              <h3>
                Professional and mature financial team
              </h3>
              <span
                >Excellent data actuaries and financial actuaries from different
                regions</span
              >
            </div>
          </li>
          <li>
            <div>
              <h3>
                A strong and professional technical team
              </h3>
              <span
                >Constantly learn cutting-edgetechnologies and always pay
                attention to the stability of platform operation</span
              >
            </div>
            <img src="@/assets/image/newAbout/t2.png" alt="" />
          </li>
          <li>
            <img src="@/assets/image/newAbout/t3.png" alt="" />
            <div>
              <h3>
                Excellent and keen operation team
              </h3>
              <span
                >Constantly understand and learn local policies and
                regulations</span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/headerNew.vue";
import Footer from "../../components/foot.vue";
export default {
  name: "",
  components: {
    Header,
    Footer,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.contentBox {
  .platFormBox {
    padding: 0 260px;
    margin: 72px 0 120px;
    h2 {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2a2a2a;
    }
    .remark {
      margin: 8px 0 50px 0;
      font-size: 24px;
      color: #7c7c7c;
      line-height: 33px;
    }
    .contentList {
      display: flex;
      justify-content: space-between;
      li {
        width: 324px;
        height: 338px;
        margin-right: 50px;
        h5 {
          font-size: 28px;
          font-weight: 400;
          font-family: AppleSystemUIFont;
          color: #333333;
          text-align: center;
          margin-bottom: 32px;
        }
        img {
          width: 100px;
          height: 100px;
          margin: 0 auto 16px;
        }
        p {
          text-align: center;
          font-size: 28px;
          font-family: AppleSystemUIFont;
          color: #666666;
          line-height: 38px;
        }
      }
    }
  }

  .operationBox {
    padding: 0 260px;
    margin-bottom: 70px;
    h2 {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2a2a2a;
    }
    .remark {
      margin: 8px 0 64px 0;
      font-size: 24px;
      color: #7c7c7c;
      line-height: 33px;
    }
    ul {
      padding: 0 66px;
      li {
        display: flex;
        width: 100%;
        margin-bottom: 127px;
        justify-content: space-between;
        div {
          width: 479px;
        }
        img {
          width: 522px;
          height: 330px;
          // margin-right: 240px;
        }
        h3 {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 20px;
        }
        span {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #7c7c7c;
        }
      }
    }
  }
}
</style>
