<template>
  <div>
    <Header :languagesProp="'English'" />
    <div class="contentBox">
      <!-- banner Box -->
      <img
        style="width: 100%"
        src="@/assets/image/newAbout/bannerUrdo.png"
        alt=""
      />
      <!-- platFormBox -->
      <div class="platFormBox">
        <!-- <img
          style="margin-bottom: 16px; width: 112px"
          src="@/assets/image/newHome/titleTop.png"
          alt=""
        /> -->
        <h2>پلیٹ فارم کے فوائد</h2>
        <p class="remark">دوسروں پر فائدہ</p>
        <ul class="contentList">
          <li>
            <img src="@/assets/image/newAbout/p1.png" alt="" />
            <h5>سروس کے فوائد</h5>
            <p>
              پیشہ ورانہ، سائنسی اور ہمہ جہت ڈیجیٹل آپریشن
            </p>
          </li>
          <li>
            <img src="@/assets/image/newAbout/p2.png" alt="" />
            <h5>ٹیم کے فوائد</h5>
            <p>
              یہ ایک بالغ اور پیشہ ور ہے مالیاتی ٹیم، آپریشن اور تکنیکی ٹیمیں
            </p>
          </li>
          <li>
            <img src="@/assets/image/newAbout/p3.png" alt="" />
            <h5>محفوظ</h5>
            <p>
              خفیہ کاری کے لیے انکرپشن الگورتھم استعمال کریں۔ آپ کی تمام معلومات
              پورے عمل، کے لئے ایک دیوار کی تعمیر رازداری
            </p>
          </li>
          <li>
            <img src="@/assets/image/newAbout/p4.png" alt="" />
            <h5 style="width: 350px">
              ڈیجیٹل خطرہ کنٹرول سسٹم
            </h5>
            <p style="width: 320px">
              مضبوط سائنسی اور تکنیکی اختراعی صلاحیت، ڈیجیٹل خطرہ کنٹرول ماڈل
            </p>
          </li>
        </ul>
      </div>

      <!-- operation Box -->
      <div class="operationBox">
        <!-- <img
          style="margin-bottom: 16px; width: 112px; margin-left: 16px"
          src="@/assets/image/newHome/titleTop.png"
          alt=""
        /> -->
        <h2>آپریشن ٹیم</h2>
        <p class="remark">پیشہ ورانہ خدمت</p>
        <ul>
          <li>
            <img src="@/assets/image/newAbout/t1.png" alt="" />
            <div>
              <h3>
                پیشہ ورانہ اور بالغ مالیاتی ٹیم
              </h3>
              <span
                >ہمارے پاس مختلف خطوں سے بہترین ڈیٹا ایکچوری اور فنانشل ایکچوریز
                ہیں۔</span
              >
            </div>
          </li>
          <li>
            <div>
              <h3>
                ایک مضبوط اور پیشہ ور تکنیکی ٹیم
              </h3>
              <span
                >ہم مسلسل جدید ٹیکنالوجی سیکھتے ہیں اور پلیٹ فارم کے آپریشن کے
                استحکام پر ہمیشہ توجہ دیتے ہیں۔</span
              >
            </div>
            <img src="@/assets/image/newAbout/t2.png" alt="" />
          </li>
          <li>
            <img src="@/assets/image/newAbout/t3.png" alt="" />
            <div>
              <h3>
                بہترین اور گہری آپریشن ٹیم
              </h3>
              <span
                >مقامی پالیسیوں اور ضوابط کو مسلسل سمجھتا اور سیکھتا ہے، اور
                پہلی بار پالیسیوں اور ضوابط کے مطابق کاروباری ایڈجسٹمنٹ کرے
                گا۔</span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/headerNew.vue";
import Footer from "../../components/foot.vue";
export default {
  name: "",
  components: {
    Header,
    Footer,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.contentBox {
  .platFormBox {
    padding: 0 260px;
    margin: 72px 0 120px;
    h2 {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2a2a2a;
      text-align: right;
    }
    .remark {
      margin: 8px 0 50px 0;
      font-size: 24px;
      color: #7c7c7c;
      line-height: 33px;
      text-align: right;
    }
    .contentList {
      display: flex;
      justify-content: space-between;
      li {
        width: 324px;
        height: 338px;
        margin-right: 50px;
        h5 {
          font-size: 28px;
          font-weight: 400;
          font-family: AppleSystemUIFont;
          color: #333333;
          text-align: center;
          margin-bottom: 32px;
        }
        img {
          width: 100px;
          height: 100px;
          margin: 0 auto 16px;
        }
        p {
          text-align: center;
          font-size: 28px;
          font-family: AppleSystemUIFont;
          color: #666666;
          line-height: 38px;
        }
      }
    }
  }

  .operationBox {
    padding: 0 260px;
    margin-bottom: 70px;
    h2 {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2a2a2a;
      text-align: right;
    }
    .remark {
      margin: 8px 0 64px 0;
      font-size: 24px;
      color: #7c7c7c;
      line-height: 33px;
      text-align: right;
    }
    ul {
      padding: 0 66px;
      li {
        display: flex;
        width: 100%;
        margin-bottom: 127px;
        justify-content: space-between;
        div {
          width: 479px;
          text-align: right;
        }
        img {
          width: 522px;
          height: 330px;
          // margin-right: 240px;
        }
        h3 {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 20px;
          text-align: right;
        }
        span {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #7c7c7c;
          text-align: right;
        }
      }
    }
  }
}
</style>
