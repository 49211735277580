<template>
  <div class="headBox">
    <img
      class="headLogoImg"
      v-show="languages == 'English'"
      src="@/assets/image/logoNew.png"
      alt=""
    />
    <ul>
      <!-- 语言切换 --- 乌尔都语 -->
      <li v-show="languages == 'اردو'">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{ languages }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="English">English</el-dropdown-item>
            <el-dropdown-item
              style="background-color: #ecf5ff; color: #66b1ff"
              class="active"
              command="اردو"
              >اردو
              <i class="el-icon-check"></i>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>

      <!-- 路由列表 -->
      <li
        @click="routerLink(item.path)"
        :class="[item.path == currentPath ? 'activeText' : '']"
        v-for="(item, index) in languages == 'English'
          ? headeList
          : headeListUrdo"
        :key="index"
      >
        <div v-if="item.path">
          {{ item.name }}
          <div v-show="item.path == currentPath" class="active"></div>
        </div>
        <!-- 跳转 --- 下拉 -->
        <div v-else>
          <el-dropdown @command="jumpPaisayar">
            <span class="el-dropdown-link">
              {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(row, i) in item.list" :key="i">{{
                row.name
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </li>
      <!-- 语言切换 --- 英语 -->
      <li v-show="languages == 'English'">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{ languages }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              style="background-color: #ecf5ff; color: #66b1ff"
              command="English"
              >English
              <i class="el-icon-check"></i>
            </el-dropdown-item>
            <el-dropdown-item command="اردو">اردو</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>
    <img
      class="headLogoImg"
      v-show="languages == 'اردو'"
      src="@/assets/image/logoNew.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    languagesProp: {
      type: String,
    },
  },
  data() {
    return {
      headeList: [
        // {
        //   name: "Home",
        //   path: "/home",
        // },
        {
          name: "Products",
          list: [
            {
              name: "Paisayaar",
            },
          ],
        },
        // {
        //   name: "About US",
        //   path: "/about",
        // },
      ],
      headeListUrdo: [
        // {
        //   name: "ہمارے بارے میں",
        //   path: "/about/urdo",
        // },
        {
          name: "مصنوعات",
          list: [
            {
              name: "Paisayaar",
            },
          ],
        },
        // {
        //   name: "ہوم پیج",
        //   path: "/home/urdo",
        // },
      ],
      languages: "English",
      currentPath: "",
    };
  },
  computed: {},
  watch: {
    languagesProp: function() {
      this.languages = this.languagesProp;
    },
  },
  created() {},
  mounted() {
    this.currentPath = this.$route.path;
    this.languages = this.$route.query.command || "English";
  },
  methods: {
    // 更换路由
    routerLink(path) {
      let that = this;
      if (path) {
        this.currentPath = path;
        this.$router.push({
          path: this.currentPath,
          query: { command: that.languages },
        });
      }
    },
    // 切换语言
    handleCommand(command) {
      if (command == this.$route.query.command) return;
      if (command == "اردو") {
        this.currentPath += "/urdo";
      } else {
        let index = this.currentPath.indexOf("/urdo");
        this.currentPath = this.currentPath.slice(0, index);
      }
      this.$router.push({ path: this.currentPath, query: { command } });
    },
    jumpPaisayar() {
      window.open("https://www.paisayaar.pk/home");
    },
  },
};
</script>

<style lang="scss" scoped>
.headBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 150px;
  box-sizing: border-box;
  background-color: transparent;
  .headLogoImg {
    width: 161px;
    height: 45px;
  }
  ul {
    display: flex;
    align-items: center;
    li,
    li span {
      position: relative;
      height: 74px;
      line-height: 68px;
      cursor: pointer;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-right: 93px;
    }
    li span {
      height: auto;
      margin-right: 0;
    }
    .el-dropdown {
      height: 47px;
    }
    li {
      &:hover {
        color: #0b793c;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .active {
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 4px;
    background: #0b793c;
    border-radius: 8px;
  }
  .activeText {
    color: #0b793c;
  }
  .el-icon-arrow-down:before {
    content: "\e790";
  }
}
</style>
