import Vue from 'vue'
import VueRouter from 'vue-router'
// import home from '@/views/home.vue'
import home from '@/views/newPage/home.vue'
// import homeUrdo from '@/views/homeUrdo.vue'
import homeUrdo from '@/views/newPage/homeUrdo.vue'
// import about from '@/views/about.vue'
import about from '@/views/newPage/about.vue'
// import aboutUrdo from '@/views/aboutUrdo.vue'
import aboutUrdo from '@/views/newPage/aboutUrdo.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home",
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/home/urdo',
    name: 'homeUrdo',
    component: homeUrdo
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/about/urdo',
    name: 'aboutUrdo',
    component: aboutUrdo
  },
]

const router = new VueRouter({
  mode: process.env.VITE_BASE_MODE,
  base: process.env.BASE_URL,
  routes
})

export default router
