<template>
  <div class="foot">
    <!-- <img src="../assets/image/logo_footer.png" alt=""> -->
    <img src="../assets/image/newHome/footerLogo.png" alt="" />
    <div class="divider"></div>
    <div class="footCenterBox" style="margin-right: 194px">
      <img src="../assets/image/newHome/footIcon.png" alt="" />
      <p>
        Apartment #3, 2nd Floor, Jispal Plaza, Plot #5, Sector G-8/4, Islamabad.
      </p>
    </div>
    <div class="concatText">
      <div style="margin-bottom: 15px">
        <img src="../assets/image/newHome/footIcon2.png" alt="" />
        <p>
          051-111-883-883
        </p>
      </div>
      <div>
        <img src="../assets/image/newHome/footIcon3.png" alt="" />
        <p>
          info@jinglecred.com
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.foot {
  display: flex;
  width: 100%;
  align-items: center;
  // height: 130px;
  background: #24272b;
  padding: 28px 360px;
  box-sizing: border-box;
  img {
    width: 176px;
    height: 56px;
    margin-right: 24px;
  }
}
.divider {
  width: 4px;
  height: 56px;
  background-color: #2EA76A;
  border-radius: 10px;
    margin-right: 194px;
}
.footCenterBox {
  display: flex;
  img {
    width: 17px;
    height: 21px;
    margin-right: 8px;
  }
  p {
    width: 340px;
    font-size: 18px;
    color: #ffffff;
    line-height: 24px;
  }
}

.concatText {
  div {
    display: flex;
    img {
      width: 18px;
      height: 18px;
      margin-right: 16px;
      margin-top: 2px;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      color: #fff;
      line-height: 24px;
      vertical-align: middle;
    }
  }
}
</style>
